/**
 * MEDIAS
 * Permet de gérer l'affichages des médias d'un élément, la sauvegarde et la suppression
 * js-medias-container : Container pour 1 element qui contient plusieurs contenus
 */

const containerElementMedias = document.querySelectorAll(".js-medias-container");

if (containerElementMedias.length > 1) {
    console.error("Il ne peut y avoir qu'un seul container de médias par page");
}

containerElementMedias.forEach(containerMedia => {
    const idElement = containerMedia.getAttribute("data-id");
    const action = containerMedia.getAttribute("data-action");
    const folder = containerMedia.getAttribute("data-folder") ?? "products";

    if (!idElement || !action) {
        console.error("Il manque des attributs à l'élément");
        return;
    }
    /**
     * MEDIAS - IMAGES ADD
     */
    const formUppyUpload = document.querySelector("#mediasUploadForm");

    formUppyUpload.addEventListener("submit", async (e) => {
        e.preventDefault();
        const data = new FormData(formUppyUpload);
        const result = await Odrazia.post(action + "_medias", {id: idElement}, data, true);
        if (result.success) {
            // On ferme la modal
            const btnClose = document.querySelector("button[data-hs-overlay='#medias_upload_modal']");
            btnClose.click();
            
            // On recharge les médias
            getMedias();
            // On vide les médias de uppy
            const uppyUploadElement  = document.getElementById('uppyUpload');
            const uppyProduct = uppyUploadElement.__instance;
            uppyProduct.cancelAll();

            // On vide le formulaire
            formUppyUpload.reset();
        }
    });
    /**
     * MEDIAS - IMAGES GET
     */
    const previewMedias = containerMedia.querySelector(".js-medias-preview");

    const getMedias = async () => {
        console.log("getMedias");
        previewMedias.classList.add("js-initialized");
        const resp = await Odrazia.get(action + "_medias", {id: idElement});
        if (resp.success) {
            const medias = resp.data.results;
            previewMedias.innerHTML = "";

            medias.forEach(elementMedia => {
                const div = document.createElement("div");
                div.classList.add("previewMedias__item");
                div.setAttribute("id", elementMedia.id);

                const actions = document.createElement("div");
                actions.classList.add("actions");
                div.appendChild(actions);

                    // const btnEdit = document.createElement("button");
                    // btnEdit.classList.add("btn", "btn-primary", "btn-sm");
                    // btnEdit.innerHTML = '<i class="fa-solid fa-pencil"></i>';
                    // btnEdit.addEventListener("click", () => {

                    const btnDelete = document.createElement("button");
                    btnDelete.classList.add("btn", "btn-danger", "btn-sm");
                    btnDelete.innerHTML = '<i class="fa-solid fa-trash"></i>';
                    btnDelete.addEventListener("click", async () => {
                        div.style.opacity = "0.5";
                        const result = await Odrazia.delete(action + "_medias_delete", {id: idElement, idElementMedia: elementMedia.id}, false);
                        // const result = await Odrazia.delete("shop_giftCards_medias_delete", {id: idGiftCard, idGiftCardMedia: elementMedia.id}, false);
                        div.style.opacity = "1";
                        if (result.success) {
                            div.remove();
                        }
                    });

                    actions.appendChild(btnDelete);


                const picture = document.createElement("picture");
                picture.classList.add("js-handle");

                    const img = document.createElement("img");
                    img.src = Routing.generate("media", {'folder': folder, 'url' : (elementMedia.media.url + "_xs.webp") });
                    // img.classList.add("w-full", "h-full", "object-cover", "object-center");
                    img.alt = elementMedia.media.alt ?? "";
                    img.loading = "lazy";

                    picture.appendChild(img);

                div.appendChild(picture);

                previewMedias.appendChild(div);
            });
            // console.log("initSortable");
            setTimeout(() => {
                initSortable();
            }, 100);
        }
    }

    /**
     * MEDIAS - IMAGES UDPATES
     */
    const btnSaveElementMedias = containerMedia.querySelector(".js-medias-save");
    btnSaveElementMedias.addEventListener("click", (e) => {
        e.preventDefault();
        updatesMedias();
    });
    const updatesMedias = async () => {
        const medias = previewMedias.querySelectorAll(".previewMedias__item");
        const data = new FormData();
        position = 1;
        medias.forEach((media, index) => {
            data.append("elementMedias["+index+"][id]", media.getAttribute("id"));
            data.append("elementMedias["+index+"][position]", position);

            position++;
        });
        const result = await Odrazia.post(action + "_medias_updates", {id: idElement}, data, true);
        // const result = await Odrazia.post("shop_giftCards_medias_updates", {id: idGiftCard}, data, true);
        if (result.success) {
            // On désactive le bouton save
            btnSaveElementMedias.disabled = true;
        }
    }

    const initSortable = () => {
        let SortableOld = previewMedias.__instance;
        if (SortableOld) {
            SortableOld.destroy();
        }
        const sortable = Sortable.create(previewMedias, {
            easing: "cubic-bezier(1, 0, 0, 1)",
            animation: 150,
            ghostClass: "is-ghost",
            fallbackClass: "is-fallback",
            forceFallback: true,
            fallbackOnBody: true,
            fallbackTolerance: 10,
            handle: ".js-handle",
            chosenClass: "is-chosen",
            onEnd: async (evt) => {
                // On affiche le button save
                btnSaveElementMedias.disabled = false;
            }
        });
        previewMedias.__instance = sortable;
    }

    /**
     * MEDIAS - IMAGES EVENTS
     */
    // console.log("previewMedias", previewMedias);
    if (previewMedias) {
        window.addEventListener('load', () => {
            const tabElementOpens = document.querySelectorAll('[role="tabpanel"]:not(.hidden)');
            tabElementOpens.forEach(element => {
                const previewMediasExist = element.querySelector(".js-medias-preview:not(.js-initialized)");
                if (previewMediasExist) {
                    getMedias();
                }
            });
        });
    
        window.addEventListener('change.hs.tab', (tabToggleEl) => {
            const tab = tabToggleEl.target;
    
            const tabElement = document.querySelector(tab.getAttribute('data-hs-tab'));    
            const previewMediasExist = tabElement.querySelector(".js-medias-preview:not(.js-initialized)");
            if (previewMediasExist) {
                getMedias();
            }
        })
    }

    // /**
    //  * Delete Element
    //  */

    // const deleteElement = document.getElementById("deleteElement");

    // if (deleteElement) {
    //     deleteElement.addEventListener('click', async (e) => {
    //         e.preventDefault();
    //         const result = await Odrazia.delete(action + "_delete", {id: idElement}, {}, true);
    //         // const result = await Odrazia.delete("shop_giftCards_delete", {id: idGiftCard}, {}, true);
    //     });
    // }
});

